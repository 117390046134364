import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
declare let pdfjsLib: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  navToggle: boolean = true;
  activeIndex: any = null;
  loading: boolean = false;
  topicName: any = "";
  constructor(private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  mainid: any;
  ngOnInit(): void {
    this.mainid = this.activatedRoute.snapshot.params['id']
    this.menuHeight = window.innerHeight
    console.log(this.menuHeight);

    this.getMenu();
  }

  menuHeight: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.menuHeight = event.target.innerHeight;
  }

  menuItems: any = [];
  getMenu() {
    this.http.get(`https://venezia.onlinepos.me/core/api/Menu/SubMenuAll?main_menu_id=${this.mainid}`).subscribe((res: any) => {
      this.menuItems = res.data;
      this.menuItems[0].active = true;
      this.topicName = this.menuItems[0].name;
      this.loadPDF(`https://venezia.onlinepos.me/uploads/${this.menuItems[0].link}`)
    })
  }

  activeMenu(items: any) {
    this.menuItems.forEach((f: any) => f.active = false);
    this.topicName = items.name;
    items.active = true;
    this.loadPDF(`https://venezia.onlinepos.me/uploads/${items.link}`);
  }

  haveFile: boolean = false;
  loadPDF(file?: any) {
    setTimeout(() => {
      let canvasDom: any = document.getElementById('canvas-dom');
      canvasDom.innerHTML = "";
      pdfjsLib.getDocument(file).then((doc: any) => {
        for (let i = 0; i < doc._pdfInfo.numPages; i++) {
          let id = i + 1
          doc.getPage(id).then((page: any) => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = page.getViewport(1).width;
            canvas.height = page.getViewport(1).height;
            canvas.classList.add('pdf-viewer');
            canvasDom.appendChild(canvas);
            page.render({
              canvasContext: ctx,
              viewport: page.getViewport(1)
            })
          })
        }
      })

    }, 500);

  }

  toBack() {
    this.router.navigate(['/'])
  }

}
