<!-- <div class="box-image">
    <img src="../assets/images/menu.png" class="bg-img">
</div>
<div class="box-content">
    <div class="container">
        <img class="logo" src="../assets/images/logo-main.png">
        <br>
        <br>
        <br>
        <br>
        <div class="buttons">
            <ng-container *ngFor="let menu of menuItems; let i = index">
                <a class="button-2 mt-3" (click)="toURL(menu)">
                    <i class="fas fa-utensils me-auto"></i>
                    <span>{{menu.name}}</span>
                    <span class="ms-auto"></span>
                </a>
            </ng-container>
        </div>
        <div class="footer">
            <p (click)="avesta()">Booking System and Digital Menu by AVESTA CO., LTD.</p>
        </div>

    </div>
</div> -->
<div class="container">
    <img src="../assets/images/menu.png" class="bg-img">
    <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-6 col-12">
            <img class="logo" src="../assets/images/logo.png" width="100%">
        </div>
    </div>
    <br>
    <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-10">
            <div class="buttons">
                <ng-container *ngFor="let menu of menuItems; let i = index">
                    <a class="button-1 mt-3" (click)="toURL(menu)">
                        <i class="fas fa-utensils me-auto"></i>
                        <span>{{menu.name}}</span>
                        <span class="ms-auto"></span>
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
    <br><br>
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="footer">
                <p (click)="avesta()">Booking System and Digital Menu by AVESTA CO., LTD.</p>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
</div>