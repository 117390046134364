import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.getMainMenu();
  }

  avesta() {
    window.open("https://www.avesta.co.th/products/restaurant-booking-system-th/");
  }

  menuItems: any;
  getMainMenu() {
    this.http.get(`https://venezia.onlinepos.me/core/api/Menu/MenuMainAll?merchants_id=11`).subscribe((res: any) => {
      this.menuItems = res.data;
    })
  }

  toURL(items: any) {
    this.router.navigate(['/', items.name, items.main_menu_id])
  }
}
