<article id="app" [ngClass]="{ 'sm': navToggle }">
    <nav class="nav-header">
        {{ topicName }}
    </nav>

    <button class="sb-menu-fixed" (click)="navToggle = false">
        <span class="fas fa-bars"></span>
    </button>
    <button class="sb-menu-fixed back" (click)="toBack()">
        <i class="fas  fa-angle-left"></i>
    </button>
    <aside>
        <header class="sb-header">
            <button class="sb-menu" (click)="navToggle = true">
                <span class="material-icons">menu</span>
            </button>
            <img src="./../../../assets/images/logo.png" alt="Logo" class="logo">
        </header>
        <div class="box-menu" [ngStyle]="{'height.px':menuHeight-200}" style="overflow-y: auto;">
            <nav>
                <ul class="sb-nav">
                    <li *ngFor="let item of menuItems" [ngClass]="{'active':item.active}" (click)="activeMenu(item)">
                        <a>{{ item.name }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="toback" (click)="toBack()">
            <i class="fas  fa-angle-left"></i>
            <span> กลับไปหน้าหลัก</span>
        </div>
    </aside>
    <section>
        <div class="inner" id="canvas-dom" [ngStyle]="{'max-height.px':menuHeight}"></div>
    </section>

    <div class="loading-container" *ngIf="loading">
        <div class="loader"></div>
        <h3 class="mt-2">LOADING</h3>
    </div>
</article>